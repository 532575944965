<template>
  <Profile
    :user.sync="user"
    :canEdit="true"
    :postModalB.sync="postModalB"
    :currentPostB.sync="currentPostB"
    @finishLoading="$emit('finishLoading')"
    @sharePost="sharePost"
    @openStory="openStory"
  />
</template>

<script>
import Profile from "@/components/user/Profile.vue"

export default {
  props: {
    postModal: {
      type: Boolean,
      default: false
    },
    currentPost: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      user: this.$store.getters["auth/getUser"],
      currentPostB: this.currentPost,
      postModalB: this.postModalB
    }
  },
  components: {
    Profile
  },
  methods: {
    sharePost(data) {
      this.$emit("sharePost", data)
    },
    openStory(data) {
      this.$emit("openStory", data)
    },
    getUser() {
      this.$store
        .dispatch("global/getItem", {
          route: "/user",
          module: "user",
          id: this.user.id
        })
        .then((response) => {
          if (response.code === 200) {
            this.user = response.data
            //Update loggedUser
            this.$store.dispatch("auth/setUser", this.user)
          }
        })
    }
  },
  created() {
    this.$emit("startLoading")
    this.getUser()
    if (this.user.link === " ") this.user.link = ""
  },
  watch: {
    postModal() {
      this.postModalB = this.postModal
    },
    currentPost() {
      this.currentPostB = this.currentPost
    },
    currentPostB() {
      this.$emit("update:currentPost", this.currentPostB)
    },
    postModalB() {
      this.$emit("update:postModal", this.postModalB)
    }
  }
}
</script>
