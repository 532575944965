<template>
  <Profile :user.sync="user" :canEdit="false" :postModalB.sync="postModalB" :currentPostB.sync="currentPostB"
    @finishLoading="$emit('finishLoading')" @openPostReport="openPostReport" @sharePost="sharePost"
    @openStory="openStory" />
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Profile from "@/components/user/Profile.vue"

export default {
  props: {
    postModal: {
      type: Boolean,
      default: false
    },
    currentPost: {
      type: Object,
      default: () => { }
    }
  },

  data() {
    return {
      user_id: this.$route.params.id || null,
      user: {},
      currentPostB: this.currentPost,
      postModalB: this.postModalB
    }
  },
  components: {
    Profile
  },
  methods: {
    sharePost(data) {
      this.$emit("sharePost", data)
    },
    openStory(data) {
      this.$emit("openStory", data)
    },
    openPostReport(post) {
      this.$emit("openPostReport", post)
    },
    getUser() {
      this.$emit("startLoading")
      this.$store
        .dispatch("global/getItem", {
          route: "/user/find_by_username",
          module: "user",
          id: this.user_id
        })
        .then((response) => {
          if (response.code === 200) {
            this.user = response.data
          } else {
            if (response.code === 404) return this.$router.push("/notfound")
            if (response.code === 403) {
              this.$router.go(-1)
              return notify().then(({ notification }) => {
                notification("user", 403, this.$snotify, this.$i18n.locale)
              })
            }
            notify().then(({ notification }) => {
              notification(
                "error",
                response.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    }
  },
  created() {
    if (this.user_id) {
      this.getUser()
    } else {
      notify().then(({ notification }) => {
        notification("error", 404, this.$snotify, this.$i18n.locale)
      })
    }
  },
  watch: {
    postModal() {
      this.postModalB = this.postModal
    },
    currentPost() {
      this.currentPostB = this.currentPost
    },
    currentPostB() {
      this.$emit("update:currentPost", this.currentPostB)
    },
    postModalB() {
      this.$emit("update:postModal", this.postModalB)
    },
    $route() {
      if (this.$route.params.id) {
        this.user_id = this.$route.params.id
        this.getUser()
      }
    }
  }
}
</script>
