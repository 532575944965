<template>
  <div>
    <div class="w-full">
      <div class="relative" v-if="canEdit">
        <header-upload class="w-full object-cover" style="max-height: 40vh" :user="user" @userUpdate="userUpdate" />
      </div>
      <img v-else class="w-full object-cover z-20" style="max-height: 40vh" :src="
        user.header_picture
          ? user.header_picture
          : '/static/banner-placeholder.jpg'
      " alt="Profile header" />
      <profile-card :user="user" :canEdit="canEdit" :canView="allowedToReadProfile" @userUpdate="userUpdate"
        :cardOffset="'-mt-16 md:-mt-32'" @openStory="openStory" />

      <div class="mt-2 mx-auto flex justify-center w-full md:w-2/3">
        <t-card :variant="theme === 'dark' ? 'dark' : 'light'" class="w-full md:w-1/3 hover:bg-translucid-black-darker"
          :class="
            selectedType === 'pictures'
              ? 'bg-jevi hover:bg-jevi-translucid'
              : ''
          " @click.native="
  allowedToReadProfile ? setSelectedData('pictures') : ''
" :style="allowedToReadProfile ? 'cursor: pointer;' : ''">
          <p class="mt-2 text-black dark:text-white text-center">
            {{ $i18n.t("pictures") }}
          </p>
          <p class="text-sm text-black dark:text-white text-center">
            {{ pictures.length }}
          </p>
        </t-card>
        <div class="mx-5" :style="allowedToReadProfile ? 'cursor: pointer;' : ''"
          @click="allowedToReadProfile ? setSelectedData('all_posts') : ''">
          <p class="text-3xl text-black dark:text-white text-center">
            {{ all_posts.length }}
          </p>
          <p class="text-black dark:text-white text-center">
            {{ $i18n.t("posts") }}
          </p>
        </div>
        <t-card :variant="theme === 'dark' ? 'dark' : 'light'" class="w-full md:w-1/3 hover:bg-translucid-black-darker"
          :class="
            selectedType === 'videos' ? 'bg-jevi hover:bg-jevi-translucid' : ''
          " @click.native="allowedToReadProfile ? setSelectedData('videos') : ''"
          :style="allowedToReadProfile ? 'cursor: pointer;' : ''">
          <p class="mt-2 text-black dark:text-white text-center">
            {{ $i18n.t("videos") }}
          </p>
          <p class="text-sm text-black dark:text-white text-center">
            {{ videos.length }}
          </p>
        </t-card>
      </div>

      <div v-if="allowedToReadProfile" class="mt-2 mx-auto flex justify-center w-full md:w-2/3">
        <img @click="viewMode = 'gallery'" src="/static/icons/gallery_mode.svg" alt="Gallery mode icon"
          class="mx-auto dark:filter invert" style="width: 35px; cursor: pointer" />
        <img @click="viewMode = 'post'" src="/static/icons/post_mode.svg" alt="Post mode icon"
          class="mx-auto dark:filter invert" style="width: 35px; cursor: pointer" />
      </div>
      <div v-else class="mt-3 mx-auto flex flex-col justify-center items-center w-full md:w-2/3">
        <font-awesome-icon class="my-5 text-black dark:text-cream" :style="{ 'font-size': '70px' }"
          :icon="['fas', 'user-lock']" />
        <p class="w-full md:w-2/3 mx-auto mt-2 text-lg sm:text-xl text-center dark:text-white">
          {{ $i18n.t("user_private") }}
        </p>
      </div>

      <p v-if="selectedData.length === 0"
        class="w-full md:w-2/3 mx-auto mt-2 text-lg sm:text-xl text-center dark:text-white">
        {{ $i18n.t("no_posts") }}
      </p>

      <template v-if="allowedToReadProfile">
        <div v-if="viewMode === 'gallery'" class="mt-2 mx-auto w-full md:w-2/3 grid grid-cols-3" ref="galleryContainer">
          <gallery-mode-element v-for="element in selectedData.filter((x) =>
            [1, 2, 5].includes(x.type)
          )" @click.native="openPost(element)" :element="element" :key="'gallery-mode-' + element.id"
            :sizePx="galleryElementWidth" />
        </div>

        <div v-if="viewMode === 'post'" class="mt-2 mx-auto w-full sm:w-4/5 md:w-1/2">
          <post-mode-element v-for="element in selectedData" :element="element" :key="
            element.key
              ? 'post-mode-' + element.key
              : 'post-mode-' + element.id
          
          " :user="user" :canEdit="canEdit" @postDeleted="handlePostDeletion" :ref="'post-mode-' + element.id"
            @commentDeleted="commentDeleted" @openPostReport="openPostReport" @sharePost="sharePost"
            @updatePoll="updatePoll" @likedPost="likedPost" @endPoll="endPoll" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ProfileCard from "@/components/user/ProfileCard.vue"
import GalleryModeElement from "@/components/gallery/GalleryModeElement.vue"
import PostModeElement from "@/components/gallery/PostModeElement.vue"
const notify = () => import("@/utils/notify.js")
export default {
  components: {
    ProfileCard,
    GalleryModeElement,
    PostModeElement
  },
  props: {
    user: {
      type: Object,
      default: () => { }
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    postModalB: {
      type: Boolean,
      default: false
    },
    currentPostB: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      viewMode: "gallery",
      all_posts: [],
      loading: false,
      reachedLast: false,
      header_picture: null,
      isLoading: false,
      selectedData: [],
      selectedType: "all"
    }
  },
  computed: {
    theme() {
      return this.$store.getters["getTheme"]
    },
    allowedToReadProfile() {
      if (
        (this.user.private === 1 && this.user.followed_by_you_status === 2) ||
        this.user.private === 0 ||
        (this.loggedUser && this.user.id == this.loggedUser.id)
      ) {
        return true
      }
      return false
    },
    pictures() {
      return this.$store.getters["picture/getAllUnpaginated"]
    },
    videos() {
      return this.$store.getters["video/getAllUnpaginated"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    galleryElementWidth() {
      return Number(
        ((this.$refs.galleryContainer.offsetWidth / 3) * 2).toFixed(2)
      )
    }
  },
  methods: {
    likedPost(post) {
      const idx = this.post.map(p => p.id).indexOf(post.id)
      this.post[idx] = post
    },
    sharePost(data) {
      this.$emit("sharePost", data)
    },
    openStory(data) {
      this.$emit("openStory", data)
    },
    endPoll(data) {
      const index = this.selectedData.findIndex((x) => x.id === data.id)
      if (index !== -1) {
        Object.assign(this.selectedData[index], {
          ...this.selectedData[index],
          ended: 1
        })
      }
    },
    openPostReport(post) {
      this.$emit("openPostReport", post)
    },
    commentDeleted(comment) {
      this.$store.dispatch("picture/commentDeleted", comment)
      this.$store.dispatch("video/commentDeleted", comment)
    },
    handlePostDeletion(post) {
      this.getPosts()
      this.$store.dispatch("auth/getUserLogged")
    },
    userUpdate(user) {
      this.$emit("update:user", user)
    },
    openPost(post) {
      this.$emit("update:currentPostB", post)
      this.$emit("update:postModalB", true)

      if (this.loggedUser) {
        this.$socket.emit("post_viewed", {
          id_user: this.loggedUser.id,
          id_post: post.id
        })
      }
    },
    setSelectedData(type) {
      this.selectedType = type
      this.selectedData = this[type]
    },
    updatePoll(data) {
      const index = this.selectedData.findIndex((x) => x.id === data.poll.id)
      if (index !== -1) {
        let datax = this.selectedData[index]
        datax.questions = data.questions
        datax.answers = data.total_answers
        data.key = new Date()
        Object.assign(this.selectedData[index], datax)
      }
    },
    filterPictures() {
      let filtered_elements = this.all_posts.filter((x) => x.type === 1)
      this.$store.dispatch("picture/setUnpaginated", filtered_elements)
    },
    filterVideos() {
      let filtered_elements = this.all_posts.filter(
        (x) => x.type === 2 || x.type === 5
      )
      this.$store.dispatch("video/setUnpaginated", filtered_elements)
    },
    getPosts() {
      this.$store
        .dispatch("global/getItems", {
          route: "/post/all",
          module: null,
          noPaginate: true,
          params: {
            order_key: "created_at",
            order_value: "desc",
            id_user: this.user.id,
            active: 1
          }
        })
        .then((response) => {
          this.all_posts = response.data
          this.selectedData = response.data
          this.filterVideos()
          this.filterPictures()

          this.$emit("finishLoading")
        })
        .catch((error) => {
          this.$emit("finishLoading")
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getPublicPosts() {
      this.$store
        .dispatch("global/getItems", {
          route: "post/noauth/all",
          module: null,
          noPaginate: true,
          params: {
            order_key: "created_at",
            order_value: "desc",
            id_user: this.user.id,
            active: 1
          }
        })
        .then((response) => {
          this.all_posts = response.data
          this.selectedData = response.data
          this.filterVideos()
          this.filterPictures()

          this.$emit("finishLoading")
        })
        .catch((error) => {
          this.$emit("finishLoading")
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    }
  },
  mounted(){
    console.log('TEST')
    if (this.user.id) 
    {
      console.log(this.user)
      if(this.user.private == 0){
        console.log('PUBLIC PROFILE')
        this.getPublicPosts()
      }else{
        this.getPosts()
      }
     
    }
  },
  created() {
  
   
  },
  watch: {
    user: {
      handler() {
        if (this.user.id) 
    {
      console.log(this.user)
      if(this.user.private == 0){
        console.log('PUBLIC PROFILE')
        this.getPublicPosts()
      }else{
        this.getPosts()
      }
     
    }
      }
    }
  }
}
</script>
