<template>
  <div class="relative cursor-pointer">
    <icon
      v-if="[2, 5].includes(element.type)"
      icon="play"
      class="text-white text-2xl"
      style="
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -10.5px;
        margin-top: -12px;
        cursor: pointer;
      "
    />
    <img
      v-if="element.type !== 5"
      :alt="element.text"
      class="mx-auto col-span-1 object-cover px-2 py-2"
      style="aspect-ratio: 1; cursor: pointer"
      src="/static/logo_square.png"
      :ref="'loading-picture-' + element.id"
    />
    <img
      v-if="element.type === 2"
      :alt="element.text"
      class="hidden mx-auto col-span-1 object-cover px-2 py-2"
      style="aspect-ratio: 1; cursor: pointer"
      :src="picture"
      @load="onImageLoad"
      :ref="element.id"
    />
    <img
      v-if="element.type === 1"
      :alt="element.text"
      class="hidden mx-auto col-span-1 object-cover px-2 py-2"
      style="aspect-ratio: 1; cursor: pointer"
      @load="onImageLoad"
      :src="picture || element.url_video"
      :ref="element.id"
    />

    <video
      v-if="element.type === 5"
      height="100%"
      width="auto"
      class="mx-auto px-2 py-2 h-full w-auto object-cover"
      style="aspect-ratio: 1"
      :src="element.imgkit_url_video || element.url_video"
      controlsList="nodownload"
    />
  </div>
</template>

<script>
export default {
  props: {
    element: {
      type: Object,
      default: {}
    },
    sizePx: {
      type: Number,
      default: -1
    }
  },
  computed: {
    picture() {
      let url
      if (this.element.imgkit_url_picture) {
        url = this.element.imgkit_url_picture
        if (this.sizePx !== -1) url += `?tr=w-${this.sizePx},h-${this.sizePx}`
      } else {
        url = this.element.url_picture
      }
      return url
    }
  },
  data() {
    return {
      thumbnail_url: ""
    }
  },
  methods: {
    onImageLoad() {
      if (this.$refs["loading-picture-" + this.element.id])
        this.$refs["loading-picture-" + this.element.id].classList.add("hidden")
      if (this.$refs[this.element.id])
        this.$refs[this.element.id].classList.remove("hidden")
    }
  },
  created() {}
}
</script>
